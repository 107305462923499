const path = require('path')

// 当前项目根目录的路径
const curPath = __dirname
// 所有项目的根目录 eg: D:\Work\YQTrackV5
const parentPath = path.resolve(curPath, '../../')
// 全局资源的路径 D:\Work\YQTrackV5\YQTrack.Web.Release\res & 测试环境构建 走release.dev目录
const releasePath = process.env.APP_ENV === 'test' ? 'release.dev' : 'release'
const resPath = `${parentPath}/yqtrack.web.${releasePath}/res`
const BUILD_ID_FILE = 'BUILD_ID.txt'

const supportLanguage = [
  'default',
  'en',
  'zh-cn',
  'zh-hk',
  'ja',
  'ko',
  'fi',
  'pl',
  'tr',
  'cs',
  'it',
  'de',
  'es',
  'fr',
  'ru',
  'pt',
  'nl',
  'uk',
  'hu',
  'sv',
  'kk',
  'el',
  'th',
  'bg',
  'sk',
  'lt',
  'ro',
  'no',
  'sq',
  'sl',
  'sr',
  'az',
  'da',
  'mk'
]

const langConfig = {
  supportLanguage, // 支持的语种
  parentPath,
  resPath: resPath, // release库路径, 存放着词条资源
  i18nDirPath: path.resolve(__dirname, '../locales'),
  BUILD_ID_FILE,
  filename: 'global',
  buildTextDir: path.resolve(__dirname, `../locales/${BUILD_ID_FILE}`)
}

module.exports = langConfig
