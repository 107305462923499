import { supportLanguage } from '@/config/i18nConfig'
import { cloneDeep } from 'lodash-es'
import { COOP_CARRIERS_LIST, COOP_CARRIER_CONFIG } from 'src/constants/coop_carriers'
import { IKV } from '../types'
import { consumableArray, underscoreToCamelObject } from './global'

const i18n: IKV = {
  country: 'ResGCountry',
  expressGroup: 'ResGExpressGroup'
}

class Carrier {
  postal: Array<any>
  express: Array<any>
  data: any
  _lock: boolean
  _onceQueue: Array<any>
  carrierList: Array<any>
  indexList: Array<any>
  isInit: boolean
  $t: any
  constructor() {
    this.postal = []
    this.express = []
    this.data = {}
    this.carrierList = []
    this._lock = true
    this._onceQueue = []
    this.indexList = []
    this.isInit = false
    // this.updateCarrier()
    // this.initIndexList()
  }
  updateCarrier(t?: any) {
    if (this.isInit) return
    !this.$t && (this.$t = t)
    this._setData(window.ResGCarrier || [])
  }
  processCarrierData(arr: Array<any>) {
    // const postal: Array<any> = []
    // const express: Array<any> = []
    const carrierList: Array<any> = []
    const data: any = {}

    arr.forEach((item) => {
      data[item.key] = item
      // if (item.key.toString().length < 6) {
      //     postal.push(item)
      // } else
      if (item._group) {
        const expressGroupItem = this.getWord('expressGroup')?.itemsDict?.get?.(item._group) || {}
        if (expressGroupItem._hideSelect === 'false') {
          carrierList.push(item)
        }
      } else {
        carrierList.push(item)
      }
    })
    carrierList.sort(this.compareAll)
    return {
      // postal,
      // express,
      carrierList,
      data
    }
  }
  getWord(word: string) {
    return this.$t(i18n[word]).items || {}
  }
  processAll(list: Array<any>) {
    list.sort(this.compareAll)
    return list
  }

  //快递排序比较器
  compareAll(a: any, b: any) {
    const _nameA = a._name
    const _nameB = b._name
    return _nameA.localeCompare(_nameB)
  }
  initIndexList() {
    const charCodeOfA = 'A'.charCodeAt(0)
    const groups = Array(26)
      .fill('')
      .map((_, i) => ({
        title: String.fromCharCode(charCodeOfA + i),
        items: []
      }))
    console.log(groups)
  }
  getLength($t: any) {
    this.updateCarrier($t)
    return this.carrierList.length
  }
  getCarrierList() {
    this.updateCarrier()
    return this.carrierList
  }
  once(cb: any) {
    if (this._lock) {
      return new Promise((resolve) => this._onceQueue.push([resolve, cb]))
    }

    return cb ? cb : Promise.resolve()
  }

  _setData(list: Array<any>) {
    const { carrierList, data } = this.processCarrierData(list)
    this.carrierList = carrierList
    // this.postal = postal
    // this.express = express
    this.data = data
  }

  _notify() {
    while (this._onceQueue.length) {
      const [resolve, cb] = this._onceQueue.pop()
      resolve()
      cb && cb()
    }
    this._lock = false
  }

  /**
   * 关键字搜索运输商, 依赖文件, ResGCarrier, ResGExpress, ResGCountry
   * @param inputVal {String} -- 输入的内容
   * @param all {Boolean} -- 是否全搜索
   * @return Object {{keyValue: {}, keys: [], values: []}}
   */
  searchCarrier(inputVal = '') {
    this.updateCarrier()
    inputVal = inputVal.toLowerCase()
    return this.searchAll(inputVal)
  }

  searchAll(inputVal: string) {
    const countryKeys = this.searchCountryForVal(inputVal).keys
    const arr: any = []
    // const lang = window.YQ.configs.lang
    this.carrierList.forEach((carrier) => {
      // const name = (carrier['_name_' + lang] || carrier._name).toLowerCase()
      const _url = carrier._url
      const _countryKey = carrier._country_iso
      const _name = carrier._name.toLowerCase()
      const zhCnName = carrier['_name_zh-cn'].toLowerCase()
      const zhHkName = carrier['_name_zh-hk'].toLowerCase()
      if (
        countryKeys.includes(_countryKey) ||
        _name.indexOf(inputVal) > -1 ||
        zhCnName.indexOf(inputVal) > -1 ||
        zhHkName.indexOf(inputVal) > -1 ||
        (inputVal.length > 3 && _url.toLowerCase().indexOf(inputVal) > -1)
      ) {
        arr.push(carrier)
        // if (!countryPostalMap[_countryKey]) {
        //     countryPostalMap[_countryKey] = []
        // }
        // countryPostalMap[_countryKey].push(carrier)
      }
    })
    return this.processAll(arr)
  }

  /**
   * 通过输入内容，搜索国家
   * key: countryKey
   * value: country
   * @param inputVal:string
   * @return Object {{keyValue: {}, keys: [], values: []}}
   */
  searchCountryForVal(inputVal: string) {
    const keys: Array<any> = []
    const values: Array<any> = []
    const keyValue: any = {}
    const countryItems = this.getWord('country')
    // console.log(countryItems)
    countryItems.forEach((country: any) => {
      if (country._mnemonic.indexOf(inputVal) > -1 || country._name.indexOf(inputVal) > -1) {
        keys.push(country.key)
        values.push(country)
        keyValue[country.key] = country
      }
    })

    return {
      keys,
      values,
      keyValue
    }
  }

  /**
   * @function
   * @description 详细内容参见yqtrack.web.uikit-v2\global-extend\vendor-yq\__utils\string-extend.js
   */
  leftFillZero(str: string, n: number) {
    let newString
    const oriLen = str.toString().length
    const maxLen = n
    let zero = ''
    let i = 0

    if (maxLen) {
      while (i < maxLen - oriLen) {
        zero += '0'
        i++
      }
      newString = zero + str
    } else {
      newString = '0' + str
    }
    return newString
  }

  /**
   * 获取邮政或快递模型, 获取不到返回空, 依赖文件, ResGCarrier, ResGExpress
   * @param carrierKey {String} -- 邮政或快递Key
   */
  getCarrier(carrierKey: string) {
    // this.updateCarrier($t)
    return carrierKey && carrierKey !== '0' ? this.data[Number(carrierKey)] : false
  }

  /**
   * 获取邮政或快递模型(邮政包括国家exCountry模型, 快递包括分组exGroup模型), 获取不到返回空, 依赖文件, ResGCarrier, ResGExpress, ResGCountry, ResGExpressGroup
   * @param carrierKey {String} -- 邮政或快递Key
   */
  getCarrierFull(carrierKey: string) {
    if (carrierKey && carrierKey !== '0') {
      const item = this.data[Number(carrierKey)]

      if (item) {
        if (item._country && !item.exCountry) {
          item.exCountry = this.getWord('country')?.itemsDict?.get?.(this.leftFillZero(item._country, 4))
        }

        if (item._group && !item.exGroup) {
          item.exGroup = this.getWord('expressGroup')?.itemsDict?.get?.(item._group)
        }

        return item
      }
    }
    return false
  }
}

export default new Carrier()

/**
 * 获取合作物流品牌信息
 * @param pathname
 * @param lang
 */
export function getCoopBandInfoByUrl(pathname: string, lang: string) {
  // 路由包含合作运输商指定二级路由才能处理合作运输商
  if (pathname.indexOf('/brand') > -1 && pathname.split('/').length <= 3) {
    const coopCarrierAll = getCoopBandCarrierAll(COOP_CARRIER_CONFIG.items)

    if (Array.isArray(coopCarrierAll)) {
      const curCarrierName = pathname.split('/')[2]
      const coopCarriersAllData = cloneDeep(COOP_CARRIERS_LIST)

      coopCarriersAllData.forEach((item) => {
        item.name = item[`_name_${lang}`] || item._name
        item.detail = item[`_detail_${lang}`] || item._detail
        item.fullName = item._fullName ? item[`_fullName_${lang}`] || item._fullName : ''
      })

      const curCoopItem = coopCarriersAllData.find((current) => {
        const name = formatCarrierName(current.name)
        return decodeURIComponent(name.toLowerCase()) === decodeURIComponent(curCarrierName.toLowerCase())
      })

      if (curCoopItem) {
        return { currentBrandDetail: curCoopItem }
      } else {
        // 如果路由匹配不到合作运输商name，直接重定向到首页
        return { redirect: true }
      }
    } else {
      return {}
    }
  } else {
    return {}
  }
}

/**
 * 获取全部品牌合作运输商
 * @param configItems
 * @returns
 */
export function getCoopBandCarrierAll(configItems = {}) {
  if (!configItems) {
    return []
  }

  let carrierAll: string[] = []
  for (const key in configItems) {
    if (Object.prototype.hasOwnProperty.call(configItems, key)) {
      for (const arr of configItems[key]) {
        carrierAll = [...carrierAll, ...arr]
      }
    }
  }

  // @ts-ignore
  return [...new Set(carrierAll.filter((item) => item))]
}

/**
 * name存在空格，替换成"-", 默认转成小写，编码处理
 * @param name
 * @returns
 */
export function formatCarrierName(name) {
  let _name = (name || '').replace(/\s/g, '-')
  _name = encodeURIComponent(_name.toLowerCase())

  return _name
}

/**
 * 获取 www sitemap 品牌标签资源
 * @returns
 */
export function getCoopCarrierSitemapsData() {
  const langs = supportLanguage.slice(1) // 配置语言
  const _curCoopCarrier = COOP_CARRIERS_LIST // 获取合作运输商列表
  const mapsDataByKey = {}
  const mapsData: string[] = []

  for (let i = 0, len = langs.length; i < len; i++) {
    if (Array.isArray(_curCoopCarrier)) {
      for (let j = 0, jLen = _curCoopCarrier.length; j < jLen; j++) {
        const _coopCarrierName = formatCarrierName(_curCoopCarrier[j]._name)
        mapsDataByKey[_curCoopCarrier[j].key] = mapsDataByKey[_curCoopCarrier[j].key] || {}
        mapsDataByKey[_curCoopCarrier[j].key][langs[i]] = _coopCarrierName
      }
    }
  }

  Object.keys(mapsDataByKey).forEach((key) => {
    mapsData.push(mapsDataByKey[key])
  })

  return mapsData
}

/**
 * 统计信息[运输商/支持语言/用户数量/...]
 * @param carrierInfoMark
 * @returns
 */
export function carrierInfoMarkMap(carrierInfoMark: IKV) {
  for (const key in carrierInfoMark) {
    if (Object.prototype.hasOwnProperty.call(carrierInfoMark, key)) {
      carrierInfoMark[key] = parseFloat(carrierInfoMark[key])
    }
  }

  return underscoreToCamelObject(carrierInfoMark)
}

interface CoopCarriersHotProps {
  coopCarrierConfig: IKV
  coopCarrierAllList: IKV[]
  country: string | any
  locale: string
}

// www首页合作运输商hook抽离成函数方法形式
export function coopCarriersHot({ coopCarrierConfig, coopCarrierAllList = [], country, locale }: CoopCarriersHotProps) {
  // 根据country获取当前地区的合作运输商列表
  function getCurrentCoopCarrier(coopCarrierConfig, country) {
    const coopCarrierDefault = getSimpleArr(coopCarrierConfig.items.default)
    const currentCoopCarrierItem = getCurrentCoopCarrierItem(country)

    const currentCoopCarrierList = coopCarrierConfig.items[currentCoopCarrierItem] || []
    const simpleCurrentCoopCarrierList = getSimpleArr(currentCoopCarrierList)
    // 取simpleCurrentCoopCarrierList和coopCarrierDefault差集
    let difference = coopCarrierDefault.filter(function (item) {
      return simpleCurrentCoopCarrierList.indexOf(item) === -1
    })

    for (let index = 0; index < currentCoopCarrierList.length; index++) {
      if (index === 0) {
        for (let i = 0; i < 3; i++) {
          if (!currentCoopCarrierList[index][i]) {
            currentCoopCarrierList[index][i] = difference[0]
            difference = difference.slice(1)
          }
        }
      } else if (index === 1) {
        for (let j = 0; j < 4; j++) {
          if (!currentCoopCarrierList[index][j]) {
            currentCoopCarrierList[index][j] = difference[0]
            difference = difference.slice(1)
          }
        }
      } else if (index > 1) {
        for (let k = 0; k < 6; k++) {
          if (!currentCoopCarrierList[index][k]) {
            currentCoopCarrierList[index][k] = difference[0]
            difference = difference.slice(1)
          }
        }
      }
    }

    // 获取当前合作运输商items
    function getCurrentCoopCarrierItem(country) {
      const conditions = coopCarrierConfig.conditions
      for (let _i = 0; _i < conditions.length; _i++) {
        if (conditions[_i].country.indexOf(country.toUpperCase()) > -1) {
          return conditions[_i].item
        }
      }

      return conditions[conditions.length - 1].item
    }

    // 获取简单数组：重数组转简单数组去重
    function getSimpleArr(complexArr) {
      const simpleArr: any[] = []
      for (let i = 0; i < complexArr.length; i++) {
        for (let j = 0; j < complexArr[i].length; j++) {
          simpleArr.push(complexArr[i][j])
        }
      }

      return consumableArray(simpleArr)
    }
    return getSimpleArr(currentCoopCarrierList)
  }

  try {
    const coopCarrierList = getCurrentCoopCarrier(coopCarrierConfig, country || '') || []
    const carrierList = coopCarrierList?.map((key) => coopCarrierAllList?.find((item) => item.key === key))
    carrierList.forEach((item) => {
      item.name = item[`_name_${locale}`] || item._name
      item.detail = item[`_detail_${locale}`] || item._detail
      item.fullName = item._fullName ? item[`_fullName_${locale}`] || item._fullName : ''
    })

    return carrierList
  } catch (error) {
    console.log(error)

    return coopCarrierAllList
  }
}
